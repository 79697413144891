var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-alert-list"},[_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] },"mobile-breakpoint":1904,"sort-by":_vm.state.defaultSort,"sort-desc":false},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":_vm.onRowClick,"update:sortBy":function($event){return _vm.$set(_vm.state, "defaultSort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.state, "defaultSort", $event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.participant && item.participant.clone && item.type === 'registration')?_c('span',[_vm._v(_vm._s(_vm.$t('alert.clone.title')))]):(item.type === 'registration')?_c('span',[_vm._v(_vm._s(_vm.$t('alert.registration.title')))]):(item.type === 'remove')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('alert.remove.title')))]):_c('span',[_vm._v(_vm._s(_vm.$t('alert.other.title')))])]}},{key:"item.participant",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item && item.participant)?_c('span',[_vm._v(_vm._s(item.participant.firstName)+" "+_vm._s(item.participant.lastName))]):_c('span',[_vm._v("---")])]}},{key:"item.event",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.event.name))]}},{key:"item.createdAt",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'DD-MM-YYYY, HH:mm')))]):_vm._e()]}},{key:"item.updateAt",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.isRead && item.updatedAt)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.updatedAt,'DD-MM-YYYY, HH:mm')))]):_c('span',[_vm._v("---")])]}},{key:"item.readBy",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.isRead)?_c('span',[(item.readBy)?_c('span',[_vm._v(_vm._s(item.readBy.firstName)+" "+_vm._s(item.readBy.lastName))]):_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.userDeleted')))])]):_c('span',[_vm._v("---")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }