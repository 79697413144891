




































import useMisc from "@/use/misc";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const alerts = ref<any[]>([]);

    const state = reactive({
      loading: false,
      defaultSort: "date",
      headers: [
        { value: "type", text: root.$tc("layout.misc.type"), sortable: false },
        {
          value: "participant",
          text: root.$tc("layout.participant.statusItems.participant"),
          sortable: false,
        },
        {
          value: "event",
          text: root.$tc("alert.event.title"),
          sortable: false,
        },
        { value: "createdAt", text: root.$tc("alert.createdAt.title") },
        {
          value: "updateAt",
          text: root.$tc("alert.dateOfRead.title"),
          sortable: false,
        },
        {
          value: "readBy",
          text: root.$tc("alert.readBy.title"),
          sortable: false,
        },
      ],
      items: computed(() => alerts.value.map((alert) => ({ ...alert }))),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
    });

    const model = reactive({
      status: [] as string[],
    });

    const fetch = () => {
      const {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      } = state.options as DataOptions;

      const fetchData = {
        isRead: model.status || undefined,
      };

      state.loading = true;

      axiosInstance
        .get("alert", {
          params: { page, itemsPerPage, sortBy, sortDesc, ...fetchData },
        })
        .then(({ data }) => {
          alerts.value = data.alerts;
          state.total = data.total;
        })
        .catch(() => {
          alerts.value = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    onMounted(fetch);
    watch(() => model, fetch, { deep: true });
    watch(() => state.options, fetch, { deep: true });

    const onRowClick = (event: MouseEvent, data: any) => {
      goBack("panel.alert.view", { alid: data.item.id });
    };

    return {
      state,
      model,
      onRowClick,
    };
  },
});
